import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogSubmitComponent } from '../../../partage/components/dialog-submit/dialog-submit.component';
import { dialogSubmitData } from '../../../partage/components/dialog-submit/dialog-submit.interfaces';
import { AjouterCommissionEvenementComponent } from './ajouter-commission/evenement/ajouter-commission-evenement/ajouter-commission-evenement.component';
import { Client, Evenement, RegleCommission } from './commissions.interface.';
import { CommissionsService } from './commissions.service';
import { DialogComissionsEditComponent } from './dialog-comissions-edit/dialog-comissions-edit.component';

@Component({
  selector: 'app-commissions.route',
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    FormsModule,
    MatDividerModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    AjouterCommissionEvenementComponent,
  ],
  templateUrl: './commissions.route.component.html',
  styleUrl: './commissions.route.component.scss',
  animations: [
    trigger('showPanel', [
      state('true', style({ backdropFilter: 'blur(5px)', opacity: '1', zIndex: '2000' })),
      state('false', style({ backdropFilter: 'blur(0px)', opacity: '0', zIndex: '-10' })),
      transition('false => true', [animate('0.4s ease-out')]),
    ]),
    trigger('slider', [
      state('open', style({ width: '50%', minWidth: '50%' })),
      state('close', style({ width: '0', minWidth: '0' })),
      transition('open <=> close', [animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
    ]),
  ],
})
export class CommissionsRouteComponent {
  private commissionsService = inject(CommissionsService);
  private dialog = inject(MatDialog);
  nomColonne: string[] = ['nom', 'idAparte', 'date', 'regle'];
  nomColonneAvecBouton: string[] = [...this.nomColonne, 'btnModif'];
  reglesCommissionsParDefaut = this.commissionsService.regleParDefaut;

  clientSelection = this.commissionsService.clientSelection;
  filteredClient = this.commissionsService.filteredClient;
  voirPanel = this.commissionsService.voirPanel;

  listeEvenementsRegleSpecifique: Evenement[] = [
    {
      id: '123',
      nom: 'Noel pour tous',
      idAparte: 1,
      date: '01/12/2024',
      nomClient: '',
      modifiable: true,
      regle: {
        id: '00ff9b5d-b8aa-4ac1-9469-fd3d10ae387a',
        type: 'Evenement',
        libelle: 'Pour chaque place vendue, la commission web Tick&Live est de 10% avec un minimum de 1 €.',
      },
    },
    {
      id: '1234',
      nom: 'Noel pour tous 2',
      idAparte: 2,
      date: '02/12/2024',
      nomClient: '',
      modifiable: false,
      regle: {
        id: '00ff9b5d-b8aa-4ac1-9469-fd3d10ae387b',
        type: 'Evenement',
        libelle: 'Pour chaque place vendue, la commission web Tick&Live est de 5% avec un minimum de 2 €.',
      },
    },
  ];

  clearClient() {
    this.clientSelection.set(undefined);
  }
  displayClient(client?: Client): string {
    return client?.libelleClient ? client.libelleClient : '';
  }

  openDialogParamCommission(regleCommission: RegleCommission) {
    this.dialog
      .open(DialogComissionsEditComponent, { data: regleCommission })
      .afterClosed()
      .subscribe((result: RegleCommission) => {
        console.log(result);
      });
  }

  fermerPanel() {
    this.voirPanel.set(false);
  }

  openDialog(evt: Evenement) {
    const dialogData: dialogSubmitData = {
      btnAnnuler: 'Annuler',
      btnValide: 'Confimer la suppression',
      message: `Vous êtes sur le point de supprimer la règle spécifique : ${evt.regle!.libelle} sur l'évènement : ${evt.nom} du ${evt.date}`,
    };

    this.dialog
      .open(DialogSubmitComponent, { data: dialogData })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.listeEvenementsRegleSpecifique = this.listeEvenementsRegleSpecifique.filter(x => x.id !== evt.id);
          console.log(this.listeEvenementsRegleSpecifique);
        }
      });
  }
}
