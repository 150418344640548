import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DialogSubmitComponent } from '../../../../../../partage/components/dialog-submit/dialog-submit.component';
import { dialogSubmitData } from '../../../../../../partage/components/dialog-submit/dialog-submit.interfaces';
import { DialogReturn, Evenement, RegleCommission } from '../../../commissions.interface.';
import { CommissionsService } from '../../../commissions.service';
import { DialogComissionsEditComponent } from '../../../dialog-comissions-edit/dialog-comissions-edit.component';

@Component({
  selector: 'app-ajouter-commission-evenement',
  imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, FormsModule, MatButtonModule, MatIconModule],
  templateUrl: './ajouter-commission-evenement.component.html',
  styleUrl: './ajouter-commission-evenement.component.scss',
})
export class AjouterCommissionEvenementComponent {
  private commissionsService = inject(CommissionsService);
  evts = new FormControl<Evenement[]>([]);
  regle: RegleCommission | undefined = undefined;
  voirPanel = this.commissionsService.voirPanel;
  private dialog = inject(MatDialog);
  evenements: Evenement[] = [
    {
      id: '124',
      nom: 'Noel pour tous',
      idAparte: 0,
      date: '01/12/2024',
      nomClient: '',
      modifiable: true,
      regle: undefined,
    },
    {
      id: '1234',
      nom: 'Noel pour tous',
      idAparte: 2,
      date: '02/12/2024',
      nomClient: '',
      modifiable: true,
      regle: undefined,
    },
    {
      id: '12345',
      nom: 'Noel pour tous',
      idAparte: 3,
      date: '03/12/2024',
      nomClient: '',
      modifiable: true,
      regle: undefined,
    },
    {
      id: '1236',
      nom: 'Noel pour tous',
      idAparte: 4,
      date: '04/12/2024',
      nomClient: '',
      modifiable: true,
      regle: undefined,
    },
  ];

  openDialogParamCommission() {
    this.dialog
      .open(DialogComissionsEditComponent, { data: { id: '', type: 0, libelle: '' } })
      .afterClosed()
      .subscribe((result: DialogReturn) => {
        if (result.valide) {
          this.regle = result.regle;
        }
      });
  }

  fermerSlider() {
    this.voirPanel.set(false);
  }

  SupprimerUneRegle() {
    this.regle = undefined;
  }

  openDialog(regle: RegleCommission) {
    const dialogData: dialogSubmitData = {
      btnAnnuler: 'Annuler',
      btnValide: 'Confimer la suppression',
      message: `Vous êtes sur le point de supprimer la règle spécifique : ${regle.libelle} sur les évènements`,
    };

    this.dialog
      .open(DialogSubmitComponent, { data: dialogData })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.regle = undefined;
        }
      });
  }
}
