<div class="panel_container">
  <div class="detail_evt_fermer">
    <button class="btn_fermer" mat-mini-fab aria-label="fermer panel" (click)="fermerSlider()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h4>Selectionner un évènement</h4>
  <div class="evenement_select">
    <mat-form-field>
      <mat-label>Evènements</mat-label>
      <mat-select [formControl]="evts" multiple>
        @for (evenement of evenements; track evenement) {
          <mat-option [value]="evenement">{{ evenement.idAparte }}-{{ evenement.nom }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <h4>Evènement(s) sélectionné(s)</h4>
  <div class="evenement_selection">
    <ul>
      @for (evt of evts.value; track evt) {
        <li>{{ evt.idAparte }} - {{ evt.nom }} du {{ evt.date }}</li>
      }
    </ul>
  </div>
  <div class="regle_container">
    @if (evts.value && evts.value.length > 0) {
      <h4>Sélectionner une règle</h4>
      <div>
        <button class="bsbtn btn-primaire" mat-flat-button (click)="openDialogParamCommission()">
          Ajouter une règle
        </button>
      </div>
      @if (regle) {
        <h4>Règle à appliquer</h4>
        <div class="regle_sub_container">
          <span class="regle_libelle">{{ regle.libelle }}</span>

          <div class="btnGroupe">
            <button
              class="icon_btn icon_primaire"
              mat-mini-fab
              matTooltip="Modifier la règle"
              aria-label="Modifier la règle"
              (click)="openDialogParamCommission()"
            >
              <mat-icon>edit_note</mat-icon>
            </button>
            <button
              class="icon_btn icon_danger"
              mat-mini-fab
              matTooltip="Supprimer la règle"
              aria-label="Supprimer la règle"
              (click)="openDialog(regle)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      }
    }
  </div>
  <div class="footer_container">
    <button type="button" mat-flat-button class="bsbtn btn-secondaire" (click)="fermerSlider()">Annuler</button>
    <button type="button" mat-flat-button class="bsbtn btn-primaire" (click)="fermerSlider()">Valider</button>
  </div>
</div>
