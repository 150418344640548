import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './partage/components/login/login.component';
import { PageNotFoundComponent } from './partage/components/page-not-found/page-not-found.component';
import { CommissionsRouteComponent } from './routes/parametrage/commissions/commissions.route.component';
import { RapprochementManuelRouteComponent } from './routes/rapprochement/rapprochement-manuel/rapprochement-manuel.route.component';
import { EvenementsRouteComponent } from './routes/redditions/evenements/evenements.route.component';
import { FormulesRouteComponent } from './routes/redditions/formules/formules.route/formules.route.component';
import { VersionComponent } from './routes/version/version/version.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'redditions/evenements',
        component: EvenementsRouteComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'redditions/formules',
        component: FormulesRouteComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'rapprochement/rapprochement-manuel',
        component: RapprochementManuelRouteComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'version',
        component: VersionComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'rapprochement/rapport/telecharger',
        redirectTo: 'rapprochement/rapprochement-manuel',
      },
      {
        path: 'parametrage/commissions',
        component: CommissionsRouteComponent,
        canActivate: [MsalGuard],
      },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
];
