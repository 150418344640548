<div class="body-container">
  <div class="content-header rm_header"></div>
  <div class="version_container">
    <mat-accordion>
      @for (item of x; track x) {
        <mat-expansion-panel hideToggle expanded="{{ $index === 0 }}">
          <mat-expansion-panel-header>
            <mat-panel-title class="version"
              ><div class="test"></div>
              {{ item.version }}</mat-panel-title
            >
            <mat-panel-description>{{ item.date | date }} </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="content">
            <mat-list role="list">
              @for (content of item.content; track content) {
                <mat-list-item role="listitem" class="list_content">
                  <div class="content_item">{{ content }}</div>
                </mat-list-item>
              }
            </mat-list>
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</div>
