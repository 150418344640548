import { Component } from '@angular/core';
import { ConnexionService } from './connexion.service';

@Component({
  selector: 'app-login',
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  constructor(private conServ: ConnexionService) {}

  annee = new Date().getFullYear();
  login() {
    this.conServ.loginRedirect();
  }
}
