import { Component, inject, model } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { dialogSubmitData } from './dialog-submit.interfaces';

@Component({
  selector: 'app-dialog-submit',
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  templateUrl: './dialog-submit.component.html',
})
export class DialogSubmitComponent {
  readonly dialogRef = inject(MatDialogRef<DialogSubmitComponent>);
  readonly data = inject<dialogSubmitData>(MAT_DIALOG_DATA);
  readonly valide = model<boolean>(true);
  readonly refuse = model<boolean>(false);
}
