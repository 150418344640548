import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, finalize, Observable, of, retry } from 'rxjs';
import { AlertService } from '../../components/alert/alert.service';
import { ChargementService } from '../../components/chargement/chargement.service';

const RETRY_TIMES = 5;
const DELAY = 2000;

export function httpIntercepteur(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const isLoading = inject(ChargementService);
  const alert = inject(AlertService);

  let isSuccess: boolean = true;

  const to = setTimeout(() => {
    isLoading.isLoading.set(true);
  }, 700);

  return next(req).pipe(
    retry({ count: RETRY_TIMES, delay: DELAY }),
    catchError(error => {
      console.log(error);
      let errorMessage = 'An unexpected error has occurred.';
      if (error instanceof HttpErrorResponse) {
        const errorsBase = error.error?.errors
          ? error.error?.errors[0]?.description
          : 'Une erreur est survenue, contactez le support.';
        if (error.error instanceof ErrorEvent && error.error) {
          console.log('an error occured : ', error.error.message);
          errorMessage = `Client-side error:' ${error.error.message} : ${errorsBase}`;
        } else {
          switch (error.status) {
            case HttpStatusCode.Unauthorized:
              errorMessage = `Unauthorized: ${errorsBase}`;
              console.error(errorMessage);
              break;
            case HttpStatusCode.Forbidden:
              errorMessage = `Forbidden:  ${errorsBase}`;
              console.error(errorMessage);
              break;
            case HttpStatusCode.NotFound:
              errorMessage = `Not Found:   ${errorsBase}`;
              console.error(errorMessage);
              break;
            case HttpStatusCode.ServiceUnavailable:
              errorMessage = `Service Unavailable:   ${errorsBase}`;
              console.error(errorMessage);
              break;
            default:
              errorMessage = `${errorsBase != '' ? errorsBase : 'Une erreur est survenue'}`;
              console.error(errorMessage);
          }
        }
      } else {
        console.error(errorMessage);
      }
      isSuccess = false;
      alert.error(errorMessage, {
        autoClose: false,
        keepAfterRouteChange: true,
      });
      return of(error);
    }),
    finalize(() => {
      if ((req.method === 'POST' || req.method === 'PUT') && isSuccess) {
        alert.success(`Votre demande a été traitée avec succès`, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }
      clearTimeout(to);
      isLoading.isLoading.set(false);
    }),
  );
}
