<div class="body-container">
  <div class="content_header rm_header">
    <div class="list_btn">
      <button type="button" mat-flat-button class="bsbtn btn-primaire" (click)="telechargerFichier('reglements')">
        Télécharger les règlements non rapprochés
      </button>
      <button type="button" mat-flat-button class="bsbtn btn-primaire" (click)="telechargerFichier('paiements')">
        Télécharger les paiements non rapprochés
      </button>
    </div>
  </div>
  <div class="content_body flex-1">
    <form [formGroup]="rapprochementForm" class="rapprochement-form aic jcc flex body-sub-container">
      <div>
        <mat-form-field appearance="outline" class="client-element">
          <mat-label>Clients</mat-label>
          <input
            #clientInput
            type="text"
            matInput
            formControlName="client"
            [matAutocomplete]="autoClient"
            (input)="filterClient()"
            (focus)="filterClient()"
            (click)="clearClient()"
          />
          @if (rapprochementForm.controls.client.value) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearClient()">
              <mat-icon>close</mat-icon>
            </button>
          } @else {
            <button matSuffix mat-icon-button>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          }
          @if (rapprochementForm.controls.client.hasError('required')) {
            <mat-error>Le client est obligatoire </mat-error>
          }
          <mat-autocomplete #autoClient="matAutocomplete" requireSelection [displayWith]="displayClient">
            @for (client of filteredClient; track client) {
              <mat-option [value]="client">{{ client.libelleClient }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>ID Règlement (Aparté)</mat-label>
          <input
            #reglementInput
            type="text"
            matInput
            formControlName="reglement"
            [matAutocomplete]="autoRgl"
            (input)="filterReglement()"
            (focus)="filterReglement()"
            (click)="clearReglement()"
          />
          @if (rapprochementForm.controls.reglement.value) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearReglement()">
              <mat-icon>close</mat-icon>
            </button>
          } @else {
            <button matSuffix mat-icon-button>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          }
          @if (rapprochementForm.controls.reglement.hasError('required')) {
            <mat-error>L'id règlement est obligatoire </mat-error>
          }

          <mat-autocomplete #autoRgl="matAutocomplete" requireSelection [displayWith]="displayReglement">
            @for (reg of filteredReglement(); track $index) {
              <mat-option [value]="reg">{{
                reg.referenceBancaire ? reg.referenceBancaire : reg.idFinancialAparte
              }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>ID Paiement (Banque)</mat-label>
          <input
            #paiementInput
            type="text"
            matInput
            formControlName="paiement"
            [matAutocomplete]="autoPaie"
            (input)="filterPaiement()"
            (focus)="filterPaiement()"
            (click)="clearPaiement()"
          />
          @if (rapprochementForm.controls.paiement.value) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearPaiement()">
              <mat-icon>close</mat-icon>
            </button>
          } @else {
            <button matSuffix mat-icon-button>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          }

          <mat-autocomplete #autoPaie="matAutocomplete" requireSelection [displayWith]="displayPaiement">
            @for (paie of filteredPaiement(); track $index) {
              <mat-option [value]="paie">{{ paie.referenceBancaire }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Montant Règlement</mat-label>
          <input
            matInput
            type="number"
            disabled="true"
            value="{{ this.rapprochementForm.controls.reglement.value?.montantEnEuros }}"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Montant Paiement</mat-label>
          <input
            matInput
            type="number"
            disabled="true"
            value="{{ this.rapprochementForm.controls.paiement.value?.montantEnEuros }}"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Commentaire</mat-label>
          <textarea matInput formControlName="commentaire" [disabled]="true"></textarea>
          @if (
            rapprochementForm.controls.commentaire.hasError('required') ||
            rapprochementForm.controls.commentaire.hasError('noWhitespace')
          ) {
            <mat-error>Le commentaire est obligatoire</mat-error>
          }
          @if (rapprochementForm.controls.commentaire.hasError('maxlength')) {
            <mat-error>Le commentaire ne doit pas dépasser 200 caractères</mat-error>
          }
        </mat-form-field>
      </div>

      <div>
        @if (
          rapprochementForm.hasError('controleMontantReglementEtPaiement') &&
          !rapprochementForm.hasError('controleGlobale')
        ) {
          <mat-error>
            <mat-card appearance="outlined"
              ><mat-card-content
                >Les montants du règlement et du paiement ne correspondent pas</mat-card-content
              ></mat-card
            ></mat-error
          >
        }
        @if (
          rapprochementForm.hasError('controleGlobale') && !rapprochementForm.controls.reglement.hasError('required')
        ) {
          <mat-error>
            <mat-card appearance="outlined"
              ><mat-card-content
                >Sélectionnez un paiement ou cochez la case non rapprochable</mat-card-content
              ></mat-card
            >
          </mat-error>
        }
      </div>

      <div>
        <mat-checkbox formControlName="nonRappochable">Non Rapprochable</mat-checkbox>
        <button
          type="submit"
          mat-flat-button
          class="btn-primaire"
          (click)="openDialog()"
          [disabled]="rapprochementForm.invalid"
        >
          Valider
        </button>
      </div>
    </form>
  </div>
</div>
