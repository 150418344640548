<div class="filter_container">
  <div class="filter">
    <mat-form-field appearance="outline" class="filter_element">
      <mat-label>Clients</mat-label>
      <input
        #clientInput
        type="text"
        matInput
        [ngModel]="clientSelection()"
        (ngModelChange)="clientSelection.set($event)"
        [matAutocomplete]="autoClient"
        (click)="$event.stopPropagation(); clearClient()"
      />
      @if (clientSelection()) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearClient()">
          <mat-icon>close</mat-icon>
        </button>
      } @else {
        <button matSuffix mat-icon-button>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      }
      <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
        @for (client of filteredClient(); track client) {
          <mat-option [value]="client">{{ client.libelleClient }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="filter_element">
      <mat-label>Evènement</mat-label>
      <input
        #evtInput
        form
        [formControl]="filterForm.controls.rechercheEvenement"
        type="text"
        matInput
        value="{{ filterForm.controls.rechercheEvenement.value }}"
      />
      @if (filterForm.controls.rechercheEvenement.value) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearEvenement()">
          <mat-icon>close</mat-icon>
        </button>
      }
      @if (filterForm.controls.rechercheEvenement.hasError('minlength')) {
        <mat-error>Minimum 3 caractères pour lancer la recherche </mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Saisir les dates</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [formControl]="filterForm.controls.filtreDateDebut" placeholder="Date début" />
        <input matEndDate [formControl]="filterForm.controls.filtreDateFin" placeholder="Date fin" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix (click)="clearDate()" (keyPress)="clearDate()" [for]="picker">
        @if (filterForm.controls.filtreDateDebut.value || filterForm.controls.filtreDateFin.value) {
          <mat-icon matDatepickerToggleIcon>close</mat-icon>
        } @else {
          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
        }
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <div class="toggles">
      <mat-slide-toggle
        appFeatureEnabled="Reddition-Archive"
        class="slider"
        checked="{{ estArchiveIsChecked }}"
        (toggleChange)="toggleEstArchiveChange()"
        labelPosition="before"
        >{{ estArchiveIsChecked ? 'Archivé' : 'Non archivé' }}</mat-slide-toggle
      >
      <mat-slide-toggle
        class="slider"
        checked="{{ aRedditionnerIsChecked }}"
        (toggleChange)="toggleARedditionnerChange()"
        labelPosition="before"
        >A redditionner uniquement</mat-slide-toggle
      >
    </div>
  </div>
  <div class="nbr_evenement">
    <p>{{ evenements().totalCount }} évènement(s)</p>
  </div>
</div>
