import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [NgOptimizedImage],
})
export class HomeComponent implements OnInit {
  title = 'Backstage';
  loginDisplay = false;
  constructor(
    private authService: MsalService,
    private msalBroadCastService: MsalBroadcastService,
  ) {}

  ngOnInit() {
    this.msalBroadCastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadCastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
}
