import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RedditionAnnulationService } from './reddition-annulation-service';
import { AnnulationForm } from './dialog-reddition-annulation.interface';
import { AnnulationDialogDataInput } from '../evenements.interface';

@Component({
  selector: 'app-dialog-reddition-annulation',
  imports: [
    FormsModule,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
  ],
  templateUrl: './dialog-reddition-annulation.component.html',
  styleUrl: './dialog-reddition-annulation.component.scss',
})
export class DialogRedditionAnnulationComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<DialogRedditionAnnulationComponent>);
  readonly data = inject<AnnulationDialogDataInput>(MAT_DIALOG_DATA);
  readonly annulationRedditionService = inject(RedditionAnnulationService);

  infosRedditionEnCours = this.annulationRedditionService.infosRedditionEnCours;

  annulationForm: FormGroup<AnnulationForm> = new FormGroup<AnnulationForm>({
    commentaire: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(3)],
      },
    ),
  });

  ngOnInit(): void {
    this.annulationRedditionService.setEvenement(this.data.evenement);
  }

  submit(): void {
    this.dialogRef.close({
      idReddition: this.infosRedditionEnCours()!.idReddition,
      commentaire: this.annulationForm.value.commentaire,
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
