<div mat-dialog-title class="dialog_title">
  <div class="dialog_header">
    <div class="dailog_img"></div>
  </div>
</div>
<mat-dialog-content class="dialog-content flex">
  <div class="content_text">
    <div [innerHtml]="infoDialog().message"></div>
  </div>
  <div class="content_input">
    <div class="content_confirm">
      @if (infoDialog().type === 'acompte') {
        <mat-form-field appearance="outline" class="form_field">
          <mat-label>Montant de l'acompte</mat-label>
          <input type="number" [formControl]="montantAcompte!" matInput (blur)="this.montantAcompte.markAsTouched()" />
          @if (montantAcompte!.hasError('required')) {
            <mat-error>Le montant est obligatoire</mat-error>
          }
          @if (montantAcompte!.hasError('min')) {
            <mat-error>Le montant minimum est de 1€</mat-error>
          }
          @if (montantAcompte!.hasError('valideMontant')) {
            <mat-error>Le montant maximum est de {{ infoDialog().MontantMaxAcompte.toFixed(2) }}€</mat-error>
          }
        </mat-form-field>
      }

      <mat-form-field appearance="outline" class="form_field">
        <mat-label>Compte bancaire</mat-label>
        <mat-select [formControl]="compteBancaire">
          <mat-select-trigger>{{ this.compteBancaire.value!.nomTitulaire }}</mat-select-trigger>
          @for (compteBancaire of listeComtpeBancaire(); track compteBancaire) {
            <mat-option [value]="compteBancaire"
              ><div class="listeCB">
                <div class="nomTit">{{ compteBancaire.nomTitulaire }}</div>
                <div class="iban">{{ compteBancaire.iban }}</div>
              </div></mat-option
            >
          }
        </mat-select>
        <mat-hint>{{ compteBancaire.value!.iban }}</mat-hint>
      </mat-form-field>

      <div class="ajout_compte" (click)="toggle()">Ajouter un compte bancaire</div>

      <mat-dialog-actions class="flex btn_liste">
        <button type="button" mat-flat-button class="btn-secondaire flex-1" [mat-dialog-close]="refuse()">
          {{ infoDialog().btnAnnuler }}
        </button>
        <button
          type="button"
          mat-flat-button
          class="btn-primaire flex-1"
          [disabled]="isValid()"
          (click)="SubmitValue()"
        >
          {{ infoDialog().btnValide }}
        </button>
      </mat-dialog-actions>
    </div>
    <!-- <form [formGroup]="ibanForm"></form> -->
    <div class="content_compteBancaire" [@creerCompteBancaire]="creerCompteBancaire() ? 'oui' : 'non'">
      <form [formGroup]="compteBancaireForm">
        <mat-form-field appearance="outline" class="form_field">
          <mat-label>Titulaire</mat-label>
          <input
            type="text"
            formControlName="nomTitulaire"
            matInput
            [value]="this.compteBancaireForm.controls.nomTitulaire.value"
          />
          @if (this.compteBancaireForm.controls.nomTitulaire.hasError('required')) {
            <mat-error>Le titulaire est obligatoire </mat-error>
          }
          @if (this.compteBancaireForm.controls.nomTitulaire.hasError('minLength')) {
            <mat-error>La longueur minimum est de 3 caractères </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline" class="form_field">
          <mat-label>Iban</mat-label>
          <input
            type="text"
            formControlName="iban"
            matInput
            placeholder="CP00 0000 0000 0000 0000..."
            [value]="this.compteBancaireForm.controls.iban.value | ibanAutoformat"
          />
          @if (this.compteBancaireForm.controls.iban.hasError('required')) {
            <mat-error>L'iban est obligatoire </mat-error>
          } @else if (this.compteBancaireForm.controls.iban.hasError('pattern')) {
            <mat-error>Le format de l'iban n'est pas correct </mat-error>
          } @else if (this.compteBancaireForm.controls.iban.hasError('ValideIban')) {
            <mat-error>L'iban n'est pas correct pour ce pays </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline" class="form_field">
          <mat-label>BIC</mat-label>
          <input
            type="text"
            formControlName="bic"
            matInput
            placeholder="AAAA BB 1C 12D"
            [value]="this.compteBancaireForm.controls.bic.value | uppercase"
          />
          @if (this.compteBancaireForm.controls.bic.hasError('required')) {
            <mat-error>Le bic est obligatoire </mat-error>
          }
          @if (this.compteBancaireForm.controls.bic.hasError('pattern')) {
            <mat-error>Le format du bic n'est pas correct </mat-error>
          }
        </mat-form-field>
        <div class="btn_liste">
          <button type="button" mat-flat-button class="btn-secondaire" (click)="toggle()">Annuler</button>
          <button
            type="button"
            mat-flat-button
            class="btn-primaire"
            [disabled]="compteBancaireForm.invalid"
            (click)="ajouterComtpeBancaire()"
          >
            Créer le compte bancaire
          </button>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
