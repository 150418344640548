import { Component, inject, model } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DialogReturn, RegleCommission } from '../commissions.interface.';

@Component({
  selector: 'app-dialog-comissions-edit',
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './dialog-comissions-edit.component.html',
  styleUrl: './dialog-comissions-edit.component.scss',
})
export class DialogComissionsEditComponent {
  readonly dialogRef = inject(MatDialogRef<DialogComissionsEditComponent>);
  readonly data = inject<RegleCommission>(MAT_DIALOG_DATA);
  selected = new FormControl('');

  montantFixe = new FormControl<number | undefined>({ value: undefined, disabled: false }, { nonNullable: false });
  pourcentage = new FormControl<number | undefined>({ value: undefined, disabled: false }, { nonNullable: false });

  SubmitValue() {
    let result = '';
    if (this.pourcentage.value) {
      result = `Pour chaque place vendue, la commission web Tick&Live est de ${this.pourcentage.value}% avec un minimum de ${this.montantFixe.value} €.`;
    } else {
      result = `Pour chaque place vendue, la commission web Tick&Live est de ${this.montantFixe.value} €.`;
    }

    this.dialogRef.close({
      valide: true,
      regle: { id: this.data.id, type: this.data.type, libelle: result },
    });
  }

  readonly refuse = model<DialogReturn>({
    valide: false,
    regle: undefined,
  });
}
