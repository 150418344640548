import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Evenement, PdfActon } from './evenements.interface';
import { EvenementsService } from './evenements.service';
import { PanelEvenementDetailComponent } from './panel-evenement-detail/panel-evenement-detail/panel-evenement-detail.component';

import { animate, state, style, transition, trigger } from '@angular/animations';
import 'moment/locale/fr';
import { PDFViewerComponent } from '../../../partage/components/PDF-viewer/pdf-viewer/pdf-viewer.component';
import { FactureService } from '../../facturation/facture.service';
import { EvenementFiltreComponent } from './evenement-filtre/evenement-filtre.component';
import { EvenementsListeComponent } from './evenements-liste/evenements-liste.component';

@Component({
  selector: 'app-evenements.route',
  templateUrl: './evenements.route.component.html',
  styleUrl: './evenements.route.component.scss',
  animations: [
    trigger('openSlider', [
      state('ouvert', style({ width: '70%', minWidth: '70%' })),
      state('fermer', style({ width: '0', minWidth: '0' })),
      transition('ouvert <=> fermer', [animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
    ]),
    trigger('voirDetail', [
      state('true', style({ backdropFilter: 'blur(5px)', opacity: '1', zIndex: '2000' })),
      state('false', style({ backdropFilter: 'blur(0px)', opacity: '0', zIndex: '0' })),
      transition('false => true', [animate('0.4s ease-out')]),
    ]),
  ],
  imports: [
    MatButtonModule,
    MatMomentDateModule,
    MatIconModule,
    MomentDateModule,
    DatePipe,
    PanelEvenementDetailComponent,
    PDFViewerComponent,
    EvenementFiltreComponent,
    EvenementsListeComponent,
  ],
})
export class EvenementsRouteComponent {
  private evenementService = inject(EvenementsService);
  private factureService = inject(FactureService);

  voirDetailEvenement = this.evenementService.voirDetail;
  panelEvenement = this.evenementService.evenementSelection;
  voirPDF = this.factureService.voirPdf;

  aRedditionnerIsChecked = false;
  estArchiveIsChecked = false;

  selection = new SelectionModel<Evenement>(true, []);
  filtre = new FormControl('');

  filteredClient = this.evenementService.filteredClient;
  clientSelection = this.evenementService.clientSelection;
  factureEnCours = this.evenementService.factureEnCours;

  pdfBlob = this.factureService.blob;
  filename = this.factureService.filename;

  statut: string = 'EnAttenteDeFacturation';

  telechargerFactureReddition(idReddition: string, action: PdfActon = PdfActon.telecharger) {
    this.factureService.redditionAction.set({
      id: idReddition,
      action: action,
    });
  }

  telechargerJustificationAcompte(idAcompte: string, action: PdfActon = PdfActon.telecharger) {
    this.factureService.acompteAction.set({
      id: idAcompte,
      action: action,
    });
  }

  telechargerDocumentBlob() {
    const blob = this.pdfBlob()!;
    this.factureService.telechargerDocBlob(blob, this.filename());
  }
  fermerPanelDetails() {
    this.voirPDF.set(false);
    this.voirDetailEvenement.set(false);
    this.panelEvenement.set(undefined);
  }
}
