<div class="alert-container">
  @for (alert of alerts(); track $index) {
    <div
      [@fade]="alert.fade"
      class="{{ cssClass(alert) }} alert-content"
      (click)="removeAlert(alert)"
      (keypress)="removeAlert(alert)"
    >
      <span [innerHTML]="alert.message"></span>
    </div>
  }
</div>
