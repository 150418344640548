<div mat-dialog-title class="dialog_title">
  <div class="dialog_header">
    <div class="dailog_img"></div>
  </div>
</div>
<mat-dialog-content class="dialog_content">
  {{ data.message }}
</mat-dialog-content>
<mat-dialog-actions class="dialog_action">
  <div class="btn_liste">
    <button type="button" mat-flat-button class="btn-secondaire" [mat-dialog-close]="refuse()">
      {{ data.btnAnnuler }}
    </button>
    <button type="button" mat-flat-button class="btn-primaire" [disabled]="" [mat-dialog-close]="valide()">
      {{ data.btnValide }}
    </button>
  </div>
</mat-dialog-actions>
