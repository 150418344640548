import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Client, Evenement, RegleCommission } from './commissions.interface.';

@Injectable({
  providedIn: 'root',
})
export class CommissionsService {
  private _httpClient = inject(HttpClient);
  voirPanel = signal<boolean>(false);

  /*---CLIENT ---*/
  clientSelection = signal<Client | undefined>(undefined);
  filterClient = signal('');
  private clients$ = this._httpClient.get<Client[]>(`${environment.backendUrl}redditions/liste-clients`);

  private clients = toSignal<Client[]>(this.clients$.pipe(startWith([] as Client[])));

  filteredClient = computed(() => {
    const clients = this.clients();
    const filter = this.filterClient();
    if (clients) {
      return clients.filter(c => c.libelleClient.toLowerCase().includes(filter));
    }
    return [] as Client[];
  });
  /*---FIN CLIENT ---*/

  /*PARMETRAGE PAR DEFAUT*/

  regleParDefaut = toSignal(
    toObservable(this.clientSelection).pipe(
      filter(Boolean),
      map(() => {
        // const href = `${environment.mockUrl}redditions/commissions/${client.codeClient}`;
        // return this._httpClient.get<RegleCommission[]>(href);
        return [
          {
            id: '00ff9b5d-b8aa-4ac1-9469-fd3d10ae387a',
            type: 'Evenement',
            libelle: 'Pour chaque place vendue, la commission web Tick&Live est de 10% avec un minimum de 1 €.',
          },
          {
            id: '00ff9b5d-b8aa-4ac1-9469-fd3d10ae387b',
            type: 'Pack',
            libelle: 'Pour chaque pack vendu, la commission web Tick&Live est égale à 1,5 €.',
          },
          {
            id: '00ff9b5d-b8aa-4ac1-9469-fd3d10ae387c',
            type: 'Abonnement',
            libelle: 'Pour chaque abonnement vendu, la commission web Tick&Live est égale à 1 €.',
          },
          {
            id: '00ff9b5d-b8aa-4ac1-9469-fd3d10ae387d',
            type: 'Produit',
            libelle: 'Pour chaque produit vendu, la commission web Tick&Live est égale à 0,74 €.',
          },
        ] as RegleCommission[];
      }),
    ),
  );

  /*FIN PARMETRAGE PAR DEFAUT*/

  /*PARMETRAGE PAR EVENEMENT*/
  evenementsSpecifiques = signal<Evenement[]>([]);

  /*FIN PARMETRAGE PAR EVENEMENT*/

  /*PARMETRAGE PAR PACK_ABO*/

  /*FIN PARMETRAGE PAR PACK_ABO*/

  /*PARMETRAGE PAR PRODUIT*/

  /*FIN PARMETRAGE PAR PRODUIT*/
}
