import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';

import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { httpIntercepteur } from './partage/Intercepteurs/HttpIntercepteur/HttpIntercepteurs';
import { provideImgixLoader } from '@angular/common';

const protectedResourceMap = new Map<string, string[]>();
protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
protectedResourceMap.set(environment.backendUrl, environment.azureAd.apiScopes);

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([httpIntercepteur])),

    provideRouter(routes),
    importProvidersFrom(
      MsalModule.forRoot(
        new PublicClientApplication({
          auth: {
            clientId: environment.azureAd.cliendId,
            authority: `https://login.microsoftonline.com/${environment.azureAd.tenantId}`,
            redirectUri: environment.azureAd.redirectUri,
            //postLogoutRedirectUri: '',
          },
          cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
          },
        }),
        {
          interactionType: InteractionType.Redirect,
        },
        {
          interactionType: InteractionType.Redirect,
          protectedResourceMap: protectedResourceMap,
        },
      ),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,

    MsalBroadcastService,
    provideAnimationsAsync(),
    provideImgixLoader(environment.frontUrlImg),
  ],
};
