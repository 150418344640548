<div class="body-container">
  <div class="content_header"></div>
  <div class="body-sub-container aic jcc">
    <div class="client_container">
      <div>
        <h4>Selectionnez un client</h4>
        <mat-form-field appearance="outline" class="filter_element">
          <mat-label>Clients</mat-label>

          <input
            #clientInput
            type="text"
            matInput
            [ngModel]="clientSelection()"
            (ngModelChange)="clientSelection.set($event)"
            [matAutocomplete]="autoClient"
            (click)="$event.stopPropagation(); clearClient()"
          />
          @if (clientSelection()) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); clearClient()">
              <mat-icon>close</mat-icon>
            </button>
          } @else {
            <button matSuffix mat-icon-button>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          }

          <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
            @for (client of filteredClient(); track client) {
              <mat-option [value]="client">{{ client.libelleClient }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    @if (clientSelection() && reglesCommissionsParDefaut()) {
      <div class="regles_commissions_defaut">
        <h4>Règles de commission par défauts</h4>
        @for (comm of reglesCommissionsParDefaut(); track comm) {
          <div class="regle_commission">
            <p class="type_commission">{{ comm.type }}</p>
            <div class="lst_regle">
              <p>{{ comm.libelle }}</p>
              <div class="btn_regle">
                <button
                  class="icon_btn icon_primaire"
                  mat-mini-fab
                  matTooltip="Modifier la règle"
                  aria-label="Modifier la règle"
                  (click)="openDialogParamCommission(comm)"
                >
                  <mat-icon>edit_note</mat-icon>
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>

  @if (clientSelection()) {
    <div class="body-sub-container aic jcc">
      <h4>Règles de commission spécifiques</h4>
      <mat-tab-group class="panel_tabs">
        <mat-tab label="Evenements">
          <ng-template matTabContent>
            <div>
              <button type="button" mat-flat-button class="bsbtn btn-primaire" (click)="voirPanel.set(true)">
                Ajouter une règle spécifique
              </button>
            </div>
            <div class="grid_container">
              <mat-table [dataSource]="listeEvenementsRegleSpecifique">
                <caption></caption>
                <ng-container matColumnDef="nom">
                  <mat-header-cell *matHeaderCellDef>Evènement</mat-header-cell>
                  <mat-cell #tdElement *matCellDef="let row">{{ row.nom }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="idAparte">
                  <mat-header-cell *matHeaderCellDef>Numéro évenement</mat-header-cell>
                  <mat-cell #tdElement *matCellDef="let row">{{ row.id }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef>Date évenement</mat-header-cell>
                  <mat-cell #tdElement *matCellDef="let row">{{ row.date }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="regle">
                  <mat-header-cell mat-header-cell *matHeaderCellDef>Règle</mat-header-cell>
                  <mat-cell #tdElement *matCellDef="let row">{{ row.regle.libelle }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="btnModif">
                  <mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</mat-header-cell>
                  <mat-cell #tdElement *matCellDef="let row">
                    <div class="btnGroupe">
                      @if (row.modifiable) {
                        <button
                          class="icon_btn icon_primaire"
                          mat-mini-fab
                          matTooltip="Modifier la règle"
                          aria-label="Modifier la règle"
                          (click)="openDialogParamCommission(row.regle)"
                        >
                          <mat-icon>edit_note</mat-icon>
                        </button>
                        <button
                          class="icon_btn icon_danger"
                          mat-mini-fab
                          matTooltip="Supprimer la règle"
                          aria-label="Supprimer la règle"
                          (click)="openDialog(row)"
                        >
                          <mat-icon>delete_forever</mat-icon>
                        </button>
                      } @else {
                        <button
                          class="icon_btn icon_danger"
                          mat-mini-fab
                          matTooltip="Règle non modifiable pour cet évènement, acompte ou reddition déjà effectué"
                          aria-label="Règle non modifiable pour cet évènement, acompte ou reddition déjà effectué"
                        >
                          <mat-icon>warning</mat-icon>
                        </button>
                      }
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="nomColonneAvecBouton"></mat-header-row>
                <mat-row *matRowDef="let row; columns: nomColonneAvecBouton"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Aucune donnée trouvée</td>
                </tr>
              </mat-table>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Formule">
          <ng-template matTabContent></ng-template>
        </mat-tab>
        <mat-tab label="Abonnement">
          <ng-template matTabContent></ng-template>
        </mat-tab>
        <mat-tab label="Produit">
          <ng-template matTabContent></ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  }
  <div class="panel_background" [@showPanel]="voirPanel()" (click)="fermerPanel()" (keypress)="fermerPanel()"></div>
  <div class="panel_container" [@slider]="voirPanel() ? 'open' : 'close'">
    @if (voirPanel()) {
      <app-ajouter-commission-evenement></app-ajouter-commission-evenement>
    }
  </div>
</div>
