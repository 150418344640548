export const environment = {
  azureAd: {
    cliendId: '497b2cc8-a8c5-4a88-a718-7c3e1a1b94db',
    tenantId: 'ee736e01-987f-46aa-a144-79812f19df56',
    redirectUri: 'https://dev.backstage.tickandlive.com',
    apiScopes: ['api://2add299f-a19c-45ea-9a24-37364c80aa53/Rapprochement'],
  },
  backendUrl: 'https://api-dev.backstage.tickandlive.com/api/',
  mockUrl: 'http://localhost:3001/',
  frontUrlImg: 'https://dev.backstage.tickandlive.com/assets/img',
};
