<div mat-dialog-title class="dialog_title">
  <div class="dialog_header">
    <div class="dailog_img"></div>
  </div>
</div>
<mat-dialog-content>
  <form [formGroup]="annulationForm">
    @if (infosRedditionEnCours(); as infosReddition) {
      <p>
        Voulez-vous annuler la reddition de
        <strong>{{ infosReddition.montantAReverserHT }}€</strong> (dont {{ infosReddition.montantCommissionHT }}€ de
        commission) pour l'évènement
        <strong>{{ infosReddition.nomEvenement }} - {{ infosReddition.idEvenementAparte }}</strong>
      </p>
    }
    <div>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Commentaire annulation</mat-label>
        <textarea formControlName="commentaire" matInput></textarea>
        @if (annulationForm.controls.commentaire.hasError('required')) {
          <mat-error>Le commentaire est obligatoire</mat-error>
        }
        @if (annulationForm.controls.commentaire.hasError('minlength')) {
          <mat-error>Le commentaire doit faire au minimum 3 caractères </mat-error>
        }
      </mat-form-field>
    </div>
    <mat-dialog-actions>
      <button (click)="close()" type="button" mat-flat-button class="btn-secondaire">Non</button>
      <button type="submit" mat-flat-button class="btn-primaire" [disabled]="!annulationForm.valid" (click)="submit()">
        Oui
      </button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
