import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FeatureFlag } from './feature-flag.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private httpClient: HttpClient) {}

  private getFeatureFlags$: Observable<FeatureFlag[]> = this.httpClient
    .get<FeatureFlag[]>(`${environment.backendUrl}feature-flags`)
    .pipe(shareReplay(1));

  isEnabled(name: string): Observable<boolean> {
    const featureFlag = this.getFeatureFlags$.pipe(
      map(featureFlags => {
        return featureFlags.find(ff => ff.featureName.toLowerCase() === name.toLowerCase());
      }),
    );
    return featureFlag.pipe(map(ff => ff?.isEnabled ?? true));
  }

  getFeaturesFlags(): Observable<Record<string, boolean>> {
    return this.getFeatureFlags$.pipe(
      map((featureFlags: FeatureFlag[]) => {
        return featureFlags.reduce(
          (acc, flag) => {
            acc[flag.featureName] = flag.isEnabled;
            return acc;
          },
          {} as Record<string, boolean>,
        );
      }),
    );
  }
}
