<div mat-dialog-title class="dialog_title">
  <div class="dialog_header">
    <div class="dailog_img"></div>
  </div>
</div>
<mat-dialog-content class="dialog_content flex">
  <div class="dialog_header_content">
    Vous etes sur le point de modifier le mode de calcul pour le type {{ data.type }}. La règle actuelle est :
    {{ data.libelle }}
  </div>
  <mat-form-field class="dialog_form_field">
    <mat-label>Règle</mat-label>
    <mat-select [formControl]="selected">
      <mat-option value="0">Montant fixe</mat-option>
      <mat-option value="1">Pourcentage avec montant minimum</mat-option>
    </mat-select>
  </mat-form-field>
  @if (selected.value === '0') {
    <mat-form-field class="dialog_form_field">
      <mat-label>Montant à appliquer</mat-label>
      <input [formControl]="montantFixe" matInput type="number" />
      <mat-icon matSuffix>euro</mat-icon>
    </mat-form-field>
  } @else if (selected.value === '1') {
    <mat-form-field class="dialog_form_field">
      <mat-label>Pourcentage à appliquer</mat-label>
      <input [formControl]="pourcentage" matInput type="number" />
      <mat-icon matSuffix>percent</mat-icon>
    </mat-form-field>
    <mat-form-field class="dialog_form_field">
      <mat-label>Montant à appliquer</mat-label>
      <input [formControl]="montantFixe" matInput type="number" />
      <mat-icon matSuffix>euro</mat-icon>
    </mat-form-field>
  }
  <mat-dialog-actions class="flex btn_liste">
    <button type="button" mat-flat-button class="btn-secondaire flex-1" [mat-dialog-close]="refuse()">Annuler</button>
    <button type="button" mat-flat-button class="btn-primaire flex-1" (click)="SubmitValue()">Valider</button>
  </mat-dialog-actions>
</mat-dialog-content>
